<style scoped lang="scss">
  .page-maintenance-employee {
    /deep/ .toolbars {
      .buttons {
        .mobile-group-button {
          padding-left: 9px;
          padding-right: 9px;
        }
      }
    }
  }
</style>

<template>
  <div class="page-maintenance-employee">
    <div class="toolbars mrgb5">
        <div class="buttons mrgb5">
            <div class="fl">
              <el-button-group class="mrgr5 mrgb5">
                  <el-button :class="$root.isPc ? '' : 'mobile-group-button'" type="primary" v-for="(tab, index) in tabs" :plain="tabIndex !== index"
                      :key="'employee_tab_' + index" size="medium" @click="tabClick(index)">{{ tab }}</el-button>
              </el-button-group>
              <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedTime" type="datetimerange" size="medium" v-show="isShowSelectTime"
                    :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" @change="_search"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    align="right">
                </el-date-picker>
            </div>
            <div class="fr">
                <el-input class="c-el-input mrgr5 mrgb5" placeholder="员工的姓名/手机号" clearable v-model="listQuery.keywords"
                    size="medium"></el-input>
                <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                <el-tooltip effect="dark" content="在系统配置中更改了挪车配置后，需手动更新下相关数据" placement="top-start">
                    <el-button type="danger" size="medium" @click="_handle()"><i class="fa fa-refresh"></i> 更新挪车数据</el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="导出员工挪车统计数据" placement="top-start">
                  <el-button class="mrgb5" type="success" size="medium" @click="openExportEmployeesExcel()"><i class="fa fa-sign-out"></i> 导出员工统计</el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="按员工分别导出对应的挪车数据" placement="top-start">
                  <el-button class="mrgb5" type="warning" size="medium" @click="openExportExcels()"><i class="fa fa-sign-out"></i> 一键导出</el-button>
                </el-tooltip>
            </div>
            <div class="clearb"></div>
        </div>
    </div>
    <el-table :data="tableData" style="width: 100%" border size="medium">
      <el-table-column label="姓名" prop="op_name"></el-table-column>
      <el-table-column label="联系电话" prop="op_phone"></el-table-column>
      <el-table-column label="任务总量" prop="total_count">
        <template slot-scope="scope">
            <el-tag type="primary" size="small" @click="navTo(scope.row, null)">{{ scope.row.total_count }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="有效挪车量/占比" prop="effective_count">
        <template slot-scope="scope">
            <el-tag type="success" size="small" @click="navTo(scope.row, 1)">
              {{ scope.row.effective_count }}
            </el-tag>
            <el-tag class="mrgl5" type="info" effect="plain" size="small" @click="navTo(scope.row, 1)">
              {{ scope.row.effective_rate }}%
            </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="无效挪车量/占比" prop="invalid_count">
        <template slot-scope="scope">
            <el-tag type="danger" size="small" @click="navTo(scope.row, 0)">
              {{ scope.row.invalid_count }}
            </el-tag>
            <el-tag class="mrgl5" type="info" effect="plain" size="small" @click="navTo(scope.row, 0)">
              {{ scope.row.invalid_rate }}%
            </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="存疑挪车量/占比" prop="indoubt_count">
        <template slot-scope="scope">
            <el-tag type="warning" size="small" @click="navTo(scope.row, 2)">
              {{ scope.row.indoubt_count }}
            </el-tag>
            <el-tag class="mrgl5" type="info" effect="plain" size="small" @click="navTo(scope.row, 2)">
              {{ scope.row.indoubt_rate }}%
            </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="160" fixed="right">
        <template slot-scope="scope">
            <el-button type="primary" plain size="mini" @click="navTo(scope.row, null)">详情</el-button>
            <el-tooltip effect="dark" :content="scope.row.op_name + '的挪车数据'" placement="left">
              <el-button type="warning" plain size="mini" @click="openExportExcel(scope.row)">导出</el-button>
            </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <!--导出excel-->
    <export-to-excel :ref="refExportToExcel"></export-to-excel>
  </div>
</template>

<script>
  import * as funMaintenance from '@/api/maintenance'
  import moment from "moment"
  import ExportToExcel from "@/components/Excels/JsonToExcel"
  import { AnalysisEmployee, AnalysisMoveTask } from "@/mixins/commonPageExport"
  export default {
    name: "pageMaintenanceEmployee",
    components: { ExportToExcel },
    data() {
      let that = this
      return {
        refExportToExcel: "refEmployeeToExportExcel",
        tableData: [],
        listQuery: {
          area: "",
          keywords: "",
          beginDate: "",
          endDate: ""
        },
        selectedTime: [moment().startOf("month").format("YYYY-MM-DD 00:00:00"), moment().format("YYYY-MM-DD 23:59:59")],
        pickerOptions: {
            shortcuts: [
                {
                    text: "昨天",
                    onClick: (picker) => {
                        let timeArr = that.setPickerTime(picker, 1, "days")
                        that.setQueryTime(timeArr)
                    }
                },
                {
                    text: "近一周",
                    onClick: (picker) => {
                        let timeArr = that.setPickerTime(picker, 1, "weeks")
                        that.setQueryTime(timeArr)
                    }
                },
                {
                    text: "近一月",
                    onClick(picker) {
                        let timeArr = that.setPickerTime(picker, 1, "months")
                        that.setQueryTime(timeArr)
                    }
                },
                {
                    text: "近三月",
                    onClick(picker) {
                        let timeArr = that.setPickerTime(picker, 3, "months")
                        that.setQueryTime(timeArr)
                    }
                },
                {
                    text: "近半年",
                    onClick(picker) {
                        let timeArr = that.setPickerTime(picker, 6, "months")
                        that.setQueryTime(timeArr)
                    }
                },
                {
                    text: "近一年",
                    onClick(picker) {
                        let timeArr = that.setPickerTime(picker, 1, "years")
                        that.setQueryTime(timeArr)
                    }
                }
            ]
        },
        tabIndex: 1,
        tabs: ["昨日", "本月", "上月", "自定义"],
        isShowSelectTime: false
      }
    },
    watch: {
        "$store.getters.serviceArea"(v) {
            this.mxIsSameActiveTabWithRoute(() => {
                this._search()
            })
        }
    },
    mounted() {
      this.tabClick(1)
    },
    methods: {
      // 重写本页的时间范围的快捷筛选，设置时间
      setPickerTime(picker, spaceTime, dateStr, isStartOf) {
          let timeArr = []
          let startTime = null
          let endTime = moment().subtract(1, "d").format("YYYY-MM-DD 23:59:59")
          startTime = moment().subtract(spaceTime, dateStr).format("YYYY-MM-DD 00:00:00")
          startTime = moment(startTime).format("YYYY-MM-DD 00:00:00")
          timeArr = [startTime, endTime]
          if (picker) {
              picker.$emit('pick', timeArr);
          }
          return timeArr
      },
      setQueryTime(timeArr) {
          this.listQuery.beginDate = timeArr[0] || ""
          this.listQuery.endDate = timeArr[1] || ""
      },
      setSelectedTime() {
          let psTime = ""
          let peTime = ""
          if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
              psTime = moment(this.selectedTime[0]).format("YYYY-MM-DD HH:mm:ss")
          }
          if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
              peTime = moment(this.selectedTime[1]).format("YYYY-MM-DD HH:mm:ss")
          }
          this.listQuery.beginDate = psTime
          this.listQuery.endDate = peTime
          this.selectedTime = [this.listQuery.beginDate, this.listQuery.endDate]
      },
      getListData() {
        this.setSelectedTime()
        window.$common.fullLoading()
        this.listQuery.area = this.$store.getters.serviceArea || ""
        funMaintenance.GetEmployeeStatistics(this.listQuery).then(response => {
          window.$common.closeFullLoading()
          this.tableData = response
        })
      },
      tabClick(index) {
        this.tabIndex = index
        switch(index) {
          case 0: {
            let endTime = moment().subtract(1, "d").format("YYYY-MM-DD 23:59:59")
            this.selectedTime = [moment(endTime).format("YYYY-MM-DD 00:00:00"), endTime]
            break
          }
          case 1: {
            this.selectedTime = [moment().startOf("month").format("YYYY-MM-DD 00:00:00"), moment().format("YYYY-MM-DD 23:59:59")]
            break
          }
          case 2: {
            this.selectedTime = [moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD 00:00:00"), moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD 23:59:59")]
            break
          }
        }
        if (index !== 3) {
          this.isShowSelectTime = false
        } else {
          this.selectedTime = []
          this.isShowSelectTime = true
        }
        this._search()
      },
      navTo(item, type) {
        this.$router.push({ name: "pageMaintenanceMoveData", params: { keyword: item.op_name, selectedTime: this.selectedTime, type, tabIndex: this.tabIndex } })
      },
      _search() {
        this.listQuery.page = 1
        this.getListData()
      },
      _handle() {
        let param = {
            area: this.listQuery.area,
            beginDate: this.listQuery.beginDate,
            endDate: this.listQuery.endDate,
            keywords: this.listQuery.keywords
        }
        window.$common.fullLoading()
        funMaintenance.HandleMoveTask(param).then(res => {
            this._search()
        })
      },
      // 导出员工挪车统计数据
      openExportEmployeesExcel() {
        if (this.total > this.maxExportCount) {
            this.warningMsg("不允许导出超过2万条数据，如有需要，请联系管理员处理!")
            return false
        }
        let searchs = JSON.parse(JSON.stringify(this.listQuery))
        searchs.page = 1
        searchs.size = this.maxExportCount
        window.$common.fullLoading()
        funMaintenance.GetEmployeeStatistics(searchs).then(res => {
          if (res && res.length > 0) {
              let tableDatas = res.map(x => {
                x.effective_rate = x.effective_rate + "%"
                x.invalid_rate = x.invalid_rate + "%"
                x.indoubt_rate = x.indoubt_rate + "%"
                return x
              })
              let exportData = ""
                if (searchs.beginDate && searchs.endDate) {
                  let sbtd = moment(searchs.beginDate).format("YYYY-MM-DD")
                  let ebtd = moment(searchs.endDate).format("YYYY-MM-DD")
                  exportData = sbtd == ebtd ? sbtd : `${sbtd}至${ebtd}`
                } else {
                  exportData = "全部"
                }
              let exportName = `${this.$store.getters.serviceArea}员工挪车统计(${exportData}).xlsx`
              AnalysisEmployee(exportName, tableDatas)
          } else {
              window.$common.closeFullLoading()
              setTimeout(() => {
                  this.alert("没有需要导出的数据!")
              }, 10)
          }
        }).catch(() => {
            console.log("export error")
        })
      },
      openExportExcels() {
        try {
            window.$common.fullLoading()
            for (let i in this.tableData) {
                ((i) => {
                    setTimeout(() => {
                        this.openExportExcel(this.tableData[i])
                        if (i >= this.tableData.length - 1) {
                            window.$common.closeFullLoading()
                        }
                    }, (i + 1) * 50);
                })(i)
            }
        } catch(e) {
            this.alert("导出数据格式不正确!")
            return false
        }
      },
      openExportExcel(row) {
        if (this.total > this.maxExportCount) {
            this.warningMsg("不允许导出超过2万条数据，如有需要，请联系管理员处理!")
            return false
        }
        let searchs = JSON.parse(JSON.stringify(this.listQuery))
        searchs.page = 1
        searchs.size = this.maxExportCount
        if (row && row.op_phone) {
          searchs.keywords = row.op_phone
        }
        window.$common.fullLoading()
        funMaintenance.GetMoveTask(searchs).then(res => {
            if (res && res.items && res.items.length > 0) {
                let tableDatas = res.items.map(x => {
                    x.start_time = this.getFormatDate(x.start_time)
                    x.end_time = this.getFormatDate(x.end_time)
                    x.first_order_time = this.getFormatDate(x.first_order_time)
                    return x
                })
                let exportData = ""
                if (searchs.beginDate && searchs.endDate) {
                  let sbtd = moment(searchs.beginDate).format("YYYY-MM-DD")
                  let ebtd = moment(searchs.endDate).format("YYYY-MM-DD")
                  exportData = sbtd == ebtd ? sbtd : `${sbtd}至${ebtd}`
                } else {
                  exportData = "全部"
                }
                let exportName = `${this.$store.getters.serviceArea}${row && row.op_name || '员工'}的挪车数据(${exportData}).xlsx`
                AnalysisMoveTask(exportName, tableDatas)
            } else {
                window.$common.closeFullLoading()
                setTimeout(() => {
                    this.alert("没有需要导出的数据!")
                }, 10)
            }
        }).catch(() => {
            console.log("export error")
        })
      }
    }
  }
</script>